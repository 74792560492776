export const COURSE_LIST_REQUEST = 'COURSE_LIST_REQUEST'
export const COURSE_LIST_SUCCESS = 'COURSE_LIST_SUCCESS'
export const COURSE_LIST_FAIL = 'COURSE_LIST_FAIL'
export const COURSE_LIST_RESET = 'COURSE_LIST_RESET'

export const COURSE_FILTER_LIST_REQUEST = 'COURSE_FILTER_LIST_REQUEST'
export const COURSE_FILTER_LIST_SUCCESS = 'COURSE_FILTER_LIST_SUCCESS'
export const COURSE_FILTER_LIST_FAIL = 'COURSE_FILTER_LIST_FAIL'
export const COURSE_FILTER_LIST_RESET = 'COURSE_FILTER_LIST_RESET'

export const POPULAR_COURSE_LIST_REQUEST = 'POPULAR_COURSE_LIST_REQUEST'
export const POPULAR_COURSE_LIST_SUCCESS = 'POPULAR_COURSE_LIST_SUCCESS'
export const POPULAR_COURSE_LIST_FAIL = 'POPULAR_COURSE_LIST_FAIL'
export const POPULAR_COURSE_LIST_RESET = 'POPULAR_COURSE_LIST_RESET'

export const DUAL_QUALIFICATION_COURSE_LIST_REQUEST = 'DUAL_QUALIFICATION_COURSE_LIST_REQUEST'
export const DUAL_QUALIFICATION_COURSE_LIST_SUCCESS = 'DUAL_QUALIFICATION_COURSE_LIST_SUCCESS'
export const DUAL_QUALIFICATION_COURSE_LIST_FAIL = 'DUAL_QUALIFICATION_COURSE_LIST_FAIL'
export const DUAL_QUALIFICATION_COURSE_LIST_RESET = 'DUAL_QUALIFICATION_COURSE_LIST_RESET'

export const FACULTY_LIST_REQUEST = 'FACULTY_LIST_REQUEST'
export const FACULTY_LIST_SUCCESS = 'FACULTY_LIST_SUCCESS'
export const FACULTY_LIST_FAIL = 'FACULTY_LIST_FAIL'
export const FACULTY_LIST_RESET = 'FACULTY_LIST_RESET'

export const ABOUT_DETAILS_REQUEST = 'ABOUT_DETAILS_REQUEST'
export const ABOUT_DETAILS_SUCCESS = 'ABOUT_DETAILS_SUCCESS'
export const ABOUT_DETAILS_FAIL = 'ABOUT_DETAILS_FAIL'
export const ABOUT_DETAILS_RESET = 'ABOUT_DETAILS_RESET'

export const LEVEL_LIST_REQUEST = 'LEVEL_LIST_REQUEST'
export const LEVEL_LIST_SUCCESS = 'LEVEL_LIST_SUCCESS'
export const LEVEL_LIST_FAIL = 'LEVEL_LIST_FAIL'
export const LEVEL_LIST_RESET = 'LEVEL_LIST_RESET'

export const OUR_QUALIFICATION_LIST_REQUEST = 'OUR_QUALIFICATION_LIST_REQUEST'
export const OUR_QUALIFICATION_LIST_SUCCESS = 'OUR_QUALIFICATION_LIST_SUCCESS'
export const OUR_QUALIFICATION_LIST_FAIL = 'OUR_QUALIFICATION_LIST_FAIL'
export const OUR_QUALIFICATION_LIST_RESET = 'OUR_QUALIFICATION_LIST_RESET'

export const OUR_QUALIFICATION_DETAILS_REQUEST = 'OUR_QUALIFICATION_DETAILS_REQUEST'
export const OUR_QUALIFICATION_DETAILS_SUCCESS = 'OUR_QUALIFICATION_DETAILS_SUCCESS'
export const OUR_QUALIFICATION_DETAILS_FAIL = 'OUR_QUALIFICATION_DETAILS_FAIL'
export const OUR_QUALIFICATION_DETAILS_RESET = 'OUR_QUALIFICATION_DETAILS_RESET'

export const COURSE_DETAILS_REQUEST = 'COURSE_DETAILS_REQUEST'
export const COURSE_DETAILS_SUCCESS = 'COURSE_DETAILS_SUCCESS'
export const COURSE_DETAILS_FAIL = 'COURSE_DETAILS_FAIL'
export const COURSE_DETAILS_RESET = 'COURSE_DETAILS_RESET'

export const DUAL_QUALIFICATION_LIST_REQUEST = 'DUAL_QUALIFICATION_LIST_REQUEST'
export const DUAL_QUALIFICATION_LIST_SUCCESS = 'DUAL_QUALIFICATION_LIST_SUCCESS'
export const DUAL_QUALIFICATION_LIST_FAIL = 'DUAL_QUALIFICATION_LIST_FAIL'
export const DUAL_QUALIFICATION_LIST_RESET = 'DUAL_QUALIFICATION_LIST_RESET'

export const DUAL_QUALIFICATION_COURSES_LIST_REQUEST = 'DUAL_QUALIFICATION_COURSES_LIST_REQUEST'
export const DUAL_QUALIFICATION_COURSES_LIST_SUCCESS = 'DUAL_QUALIFICATION_COURSES_LIST_SUCCESS'
export const DUAL_QUALIFICATION_COURSES_LIST_FAIL = 'DUAL_QUALIFICATION_COURSES_LIST_FAIL'
export const DUAL_QUALIFICATION_COURSES_LIST_RESET = 'DUAL_QUALIFICATION_COURSES_LIST_RESET'

export const DUAL_QUALIFICATION_COURSE_DETAILS_REQUEST = 'DUAL_QUALIFICATION_COURSE_DETAILS_REQUEST'
export const DUAL_QUALIFICATION_COURSE_DETAILS_SUCCESS = 'DUAL_QUALIFICATION_COURSE_DETAILS_SUCCESS'
export const DUAL_QUALIFICATION_COURSE_DETAILS_FAIL = 'DUAL_QUALIFICATION_COURSE_DETAILS_FAIL'
export const DUAL_QUALIFICATION_COURSE_DETAILS_RESET = 'DUAL_QUALIFICATION_COURSE_DETAILS_RESET'

export const APPLICATION_SEND_REQUEST = 'APPLICATION_SEND_REQUEST'
export const APPLICATION_SEND_SUCCESS = 'APPLICATION_SEND_SUCCESS'
export const APPLICATION_SEND_FAIL = 'APPLICATION_SEND_FAIL'
export const APPLICATION_SEND_RESET = 'APPLICATION_SEND_RESET'

export const SA_SEND_REQUEST = 'SA_SEND_REQUEST'
export const SA_SEND_SUCCESS = 'SA_SEND_SUCCESS'
export const SA_SEND_FAIL = 'SA_SEND_FAIL'
export const SA_SEND_RESET = 'SA_SEND_RESET'

export const CU_SEND_REQUEST = 'CU_SEND_REQUEST'
export const CU_SEND_SUCCESS = 'CU_SEND_SUCCESS'
export const CU_SEND_FAIL = 'CU_SEND_FAIL'
export const CU_SEND_RESET = 'CU_SEND_RESET'

export const BA_SEND_REQUEST = 'BA_SEND_REQUEST'
export const BA_SEND_SUCCESS = 'BA_SEND_SUCCESS'
export const BA_SEND_FAIL = 'BA_SEND_FAIL'
export const BA_SEND_RESET = 'BA_SEND_RESET'

export const CA_SEND_REQUEST = 'CA_SEND_REQUEST'
export const CA_SEND_SUCCESS = 'CA_SEND_SUCCESS'
export const CA_SEND_FAIL = 'CA_SEND_FAIL'
export const CA_SEND_RESET = 'CA_SEND_RESET'

export const EVENT_LIST_REQUEST = 'EVENT_LIST_REQUEST'
export const EVENT_LIST_SUCCESS = 'EVENT_LIST_SUCCESS'
export const EVENT_LIST_FAIL = 'EVENT_LIST_FAIL'
export const EVENT_LIST_RESET = 'EVENT_LIST_RESET'

export const STUDENT_VERIFY_CHECK_REQUEST = 'STUDENT_VERIFY_CHECK_REQUEST'
export const STUDENT_VERIFY_CHECK_SUCCESS = 'STUDENT_VERIFY_CHECK_SUCCESS'
export const STUDENT_VERIFY_CHECK_FAIL = 'STUDENT_VERIFY_CHECK_FAIL'
export const STUDENT_VERIFY_CHECK_RESET = 'STUDENT_VERIFY_CHECK_RESET'

export const SLIDER_COURSE_LIST_REQUEST = 'SLIDER_COURSE_LIST_REQUEST'
export const SLIDER_COURSE_LIST_SUCCESS = 'SLIDER_COURSE_LIST_SUCCESS'
export const SLIDER_COURSE_LIST_FAIL = 'SLIDER_COURSE_LIST_FAIL'
export const SLIDER_COURSE_LIST_RESET = 'SLIDER_COURSE_LIST_RESET'
