import React, { useEffect, useState } from 'react'
import {Input, Popover, PopoverContent, PopoverTrigger, Skeleton} from "@nextui-org/react";
import { VscSymbolKeyword } from "react-icons/vsc";
import {Button} from "@nextui-org/button";
import thum from '../assets/thum.png'
import w from '../assets/w.png'
import { IoCalendar, IoDocument, IoDocuments, IoSearch } from "react-icons/io5";
import { AiFillSignal } from "react-icons/ai";
import ReactWhatsapp from 'react-whatsapp';

import { BsBookmarksFill } from "react-icons/bs";
import { FaAward } from "react-icons/fa"; 
import {Select, SelectItem} from "@nextui-org/react";
import { MdOutlineAdsClick } from "react-icons/md";
import { MdOutlineVerified } from "react-icons/md";
import { FaIdCard } from "react-icons/fa";
import { MdLibraryBooks } from "react-icons/md";
import { MdOutlineAccessTime } from "react-icons/md";
import { MdOutlineBookmarkBorder } from "react-icons/md";
import { FaUsers } from "react-icons/fa";
import { Swiper, SwiperSlide } from 'swiper/react';
import { IoArrowForwardOutline } from "react-icons/io5";
import {RadioGroup, Radio, useRadio, VisuallyHidden, cn} from "@nextui-org/react";
import { getEuCountryList, getMainCountryList, getMedicineCountryList, getTestimonialList } from '../actions/abroadActions';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { BiSolidMessageSquareDetail } from "react-icons/bi";
import { FaCalendarAlt } from "react-icons/fa";
import { IoMdArrowRoundForward } from "react-icons/io";
import {Accordion, AccordionItem} from "@nextui-org/react";
import { GrFormNextLink } from "react-icons/gr";
import {today, isWeekend, getLocalTimeZone} from "@internationalized/date";
import {useLocale} from "@react-aria/i18n";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { setHours, setMinutes, getDay } from 'date-fns';
import WebView from '@luxbit/react-electron-webview'
import YouTube from 'react-youtube';
import  apply  from '../assets/apply.png'
import { Faqualification, FaHandHoldingUsd, FaSearchLocation, FaWhatsapp } from "react-icons/fa";

import  verification  from '../assets/verification.png'
import  sp  from '../assets/sp.png'
import  es  from '../assets/es.png'
import parse from 'html-react-parser';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css'; 
import { format } from 'date-fns';
import sa1 from '../assets/sa1.jpg'
import sa2 from '../assets/sa2.jpg'
import sa3 from '../assets/sa3.jpg'
import sa4 from '../assets/sa4.jpg'
import BANNER01 from '../assets/BANNER-01.jpg'

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

import { Pagination, Autoplay, FreeMode } from 'swiper/modules';
import ReactPlayer from 'react-player'
import CountUp from 'react-countup';

import sdv from '../assets/sdv.mp4'
import { getEventList, getSliderList, sendBA, sendBAMail, sendSA, sendSAMail } from '../actions/courseActions';

const AbroadServiceScreen = () => {
    const [value, setValue] = useState(new Date());
    const [events, setEvents] = useState([
      { date: new Date(2024, 6, 7), event: 'anniversary' },
      { date: new Date(2024, 7, 13), event: 'anniversary' },
    ]);

    const onChange = (nextValue) => {
      setValue(nextValue);
    };

    const tileContent = ({ date, view }) => {
      const event = events.find(e => e.date.toDateString() === date.toDateString());
      return event && view === 'month' ? <p>{event.event}</p> : null;
    };

  // const WebView = require('@luxbit/react-electron-webview');

  const [selected, setSelected] = React.useState("london");
  const [email, setemail] = useState('')
  const [name, setname] = useState('')
  const [mNumber, setmNumber] = useState('')
  const [YOS, setYOS] = useState('')
  const [destination, setdestination] = useState('')
  const [ndestination, setndestination] = useState('')
  const [nYOS, setnYOS] = useState('')
  const [PS, setPS] = useState('')
  const [nPS, setnPS] = useState('')
  const [SI, setSI] = useState('')
  const [nSI, setnSI] = useState('')

  const [date, setDate] = useState('')
  
  useEffect(() => {
    setnYOS(YOS.anchorKey)

  }, [YOS])

  useEffect(() => {
    setnSI(SI.anchorKey)
  }, [SI])
  useEffect(() => {
    
    setnPS(PS.anchorKey)
  }, [PS])


  useEffect(() => {
    
    setndestination(destination.anchorKey)
  }, [destination])

  const dispatch = useDispatch() 
  const history = useNavigate()
  const location = useLocation()

  const mainCountryList = useSelector(state => state.mainCountryList)
  const { error:mainCountryListError, loading:mainCountryListLoaoding, countries:mainCountries } = mainCountryList

  const euCountryList = useSelector(state => state.euCountryList)
  const { error:euCountryListError, loading:euCountryListLoaoding, countries:euCountries } = euCountryList

  const medicineCountryList = useSelector(state => state.medicineCountryList)
  const { error:medicineCountryListError, loading:medicineCountryListLoaoding, countries:medicineCountries } = medicineCountryList

  const testimonialList = useSelector(state => state.testimonialList)
  const { error: testimonialListError, loading: testimonialListLoading, testimonials } = testimonialList

  const sendBA = useSelector(state => state.sendBA)
  const { error: sendBAError, loading: sendBALoading, success:BASuccess } = sendBA

  const sendSA = useSelector(state => state.sendSA)
  const { error: sendSAError, loading: sendSALoading, success:SASuccess } = sendSA

  const SliderCourseList = useSelector(state => state.SliderCourseList)
  const { list } = SliderCourseList

  const eventList = useSelector(state => state.eventList)
  const { error: eventListError, loading: eventListLoading, eventse } = eventList

  useEffect(() => {
    if (!mainCountries){
      dispatch(getMainCountryList())
    }
 
    if (!euCountries){
      dispatch(getEuCountryList())
    }

    if(!list){
      dispatch(getSliderList())
    }


    if (!medicineCountries){
      dispatch(getMedicineCountryList())
    }

    if(!eventse){
      dispatch(getEventList('abroad')) 
    }

  }, [dispatch, mainCountries, euCountries, medicineCountries, eventse, list])

  useEffect(() => {
    dispatch(getTestimonialList('abroad'))
  }, [])

  const [startDate, setStartDate] = useState(null);

  const filterTime = (time) => {
    const selectedTime = new Date(time);
    const hours = selectedTime.getHours();
    const day = startDate ? getDay(startDate) : null;

    // Allow times between 9 AM and 5 PM on weekdays
    if (hours < 9 || hours > 15) {
      return false;
    }

    // Allow times between 9 AM and 2 PM on Saturdays
    if (day === 6 && hours >= 13) {
      return false;
    }

    return true;
  };

  const filterDate = (date) => {
    const day = getDay(date);
    return day !== 0; // Disable all Sundays
  };

  const sendMail = () =>{
      if((mNumber!='')&& (nPS!='')&& (email!='')&& (nYOS!='')&& (nSI!='')){
        dispatch(sendSAMail({ 
            "mNumber": mNumber,
            "email": email,
            "YOS": nYOS,
            "PS": nPS,
            "SI": nSI,
        }))
        setmNumber('')
        setemail('')
        setYOS('')
        setSI('')
        setPS('')
    }
  }

  const sendMailBA = () =>{
    if((mNumber!='')&& (name!='')&& (email!='')&& (nYOS!='')&&(startDate!='')&& (ndestination!='')&& (nSI!='')){
      dispatch(sendBAMail({ 
        "mNumber": mNumber,
        "name": name,
        "email": email,
        "YOS": nYOS,
        "date": startDate, 
        "destination": ndestination,
        "SI": nSI,
    }))
    setmNumber('')
    setemail('')
    setYOS('')
    setDate('')
    setdestination('')
    setSI('')
    setname('')
    }
  }

  useEffect(() => {
    window.scroll(0,0);
  }, [location]);
  return (
    
    <div className='w-full flex flex-col gap-16 ' >
      
      <section className='lg:h-[500px] w-full relative overflow-hidden'>

        <div className='lg:h-[500px] w-full object-cover object-bottom relative z-10'>
          <Swiper
            slidesPerView={1}
            spaceBetween={10}
            pagination={{
              clickable: true,
            }}
            modules={[ Autoplay]}
            className="h-fit w-full"
            autoplay
          >
            <SwiperSlide className='max-h-fit lg:h-[500px] w-full object-cover lg:object-cover'>
              <img src={'https://linstant-m.tn//uploads/3025.png'} alt='' className='max-h-fit lg:h-[500px] w-full object-cover lg:object-cover object-center'/>
            </SwiperSlide>
            
          </Swiper>
        </div>
        <div className='h-full w-full flex items-center bg-gradient-to-tr from-blue-950 to-blue-950/25 absolute top-0 z-30'>
        <Swiper
            slidesPerView={1}
            spaceBetween={10}
            pagination={{
              clickable: true,
            }}
            modules={[ Autoplay]}
            className="h-fit w-full flex items-center"
            autoplay
          >
            <SwiperSlide className='max-h-fit lg:h-[500px] w-full object-cover lg:object-cover'>
            <div className='h-full w-full max-w-[1024px] mx-auto px-[25px] sm:px-[50px] md:px-[75px] lg:px-[25px] flex flex-col gap-2 justify-center'>
              <div className=' flex flex-col gap-2 max-w-[500px] pt-[100px]'>
                <p className=' text-xs md:text-2xl  text-white uppercase'>Counselling with</p>
                <p className='font-black text-xl md:text-5xl text-white uppercase'>Get your free study abroad</p>
                <div className='mt-4 flex gap-2'>
                  <Button className='h-[35px] md:h-[50px] w-fit border-white border-2 bg-transparent font-bold text-xs md:text-lg text-white' radius='none'>
                    Follow your dreams
                  </Button>
                  <ReactWhatsapp number="+94766778810" message="Hello, Can I get more info." className=' flex items-center justify-center px-4 gap-2 h-[35px] md:h-[50px] w-fit bg-red-600 font-bold text-xs md:text-lg text-white' radius='none'>
                    <FaWhatsapp className='text-lg md:text-xl' />
                    <p>Contact us</p>
                  </ReactWhatsapp>
                </div>
              </div>
            </div>
            </SwiperSlide>
             
          </Swiper>
           
        </div>
      </section>

      <section className='h-fit max-w-full bg-white'>
        <div className='h-fit w-full flex flex-col'>
          
          <div className='w-full mx-auto h-full max-w-[1024px] grid grid-cols-1 md:grid-cols-2 gap-16 items-center text-left'>
            <div className=' w-full h-full min-h-[100%] min-w-[100%] flex flex-col gap-4'>
                <p className='text-2xl font-bold'>Complimentary Study Abroad Counselling</p>
           
                <p className='text-sm opacity-50 text-justify'>At IEBC, we provide complimentary counselling for studying abroad 
                    to assist students in their global educational pursuits. Our objective 
                    is to reduce the anxiety associated with organizing such an 
                    important journey by offering expert advice and support without 
                    charge. Understanding the widespread desire among today's youth 
                    to study abroad, we are dedicated to being a dependable 
                    consultancy that promptly meets their needs. We take satisfaction in 
                    guiding and supporting students as they pursue their dreams of
                    international education.
                </p>
            </div>
            <div className='max-w-screen h-fit flex flex-col gap-4 w-full max-w-md relative mx-8 px-4 md:px-0'>
              <img src='https://www.marketsquaredental.com/files/2011/08/book-now.png' alt='' className='absolute w-full h-full opacity-25 object-contain scale-110' />
              <div className='w-full flex flex-col gap-6'>
                <p className='text-2xl font-bold'>Book an Appointment</p>
                {
                    
                    BASuccess?
                    <p className='text-xs text-green-500 font-lg '>Message send succefully</p>:
                    <p className='text-xs text-red-500 font-lg '>All fields are required*</p>
                  }
                <Input isClearable value={name} 
                            onChange={(e) => setname(e.target.value)}  variant='flat' type='text' placeholder='Name'></Input>
                <div className='grid grid-cols-2 gap-6'>
                  <Input isClearable value={email} 
                            onChange={(e) => setemail(e.target.value)}  variant='flat' type='email' placeholder='Email'></Input>
                  <Input isClearable  value={mNumber} 
                            onChange={(e) => setmNumber(e.target.value)} variant='flat' type='number' placeholder='Mobile number' startContent={
                    <div className="pointer-events-none flex items-center">
                      <span className="text-default-400 text-small">+94</span>
                    </div>
                  }></Input>
                </div>
                <div className='h-fit w-full relative grid grid-cols-2 gap-6'>
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    showTimeSelect
                    filterTime={filterTime}
                    filterDate={filterDate}
                    dateFormat="MMMM d, yyyy h:mm aa"
                    timeIntervals={30}
                    minTime={setHours(setMinutes(new Date(), 0), 9)}
                    maxTime={setHours(setMinutes(new Date(), 0), 17)}
                    placeholderText="Select a date and time"
                    className='min-w-[100%] w-full flex py-[10px] px-3 rounded-[12px] text-sm outline-none cursor-pointer bg-gray-100 shadow-sm hover:opacity-50'
                  />
                  <div className='absolute top-1/2  transform right-2 -translate-y-1/2'>
                    <IoCalendar/>
                  </div>
                  <Select 
                    className="w-full" 
                    // selectedKeys={pLocation}
                    // onSelectionChange={setPLocation}
                    variant=''
                    placeholder='Year of Study'
                    required
                    size='md'
                    selectedKeys={YOS}
                    onSelectionChange={setYOS}

                >
                    <SelectItem key='2025' className=''>
                        2025
                    </SelectItem>
                    <SelectItem key='2027' className=''>
                        2027
                    </SelectItem>
                    <SelectItem key='2028' className=''>
                        2028
                    </SelectItem>
                </Select>
                </div>
                <div className='flex gap-6'>
                  <Select 
                      className="w-full" 
                      // selectedKeys={pLocation}
                      // onSelectionChange={setPLocation}
                      variant=''
                      placeholder='Preferred Study Destination'
                      required
                      size='md'
                      selectedKeys={destination}
                    onSelectionChange={setdestination}

                  >
                         {
                          mainCountryListLoaoding?
                          "":
                          mainCountries?
                          mainCountries.map(i => (
                            <SelectItem key={i.slug} value={i.slug} className=''>
                                {i.name}
                            </SelectItem>
                          )):''
                        }
                        {
                          euCountryListLoaoding?
                          "":
                          euCountries?
                          euCountries.map(i => (
                            <SelectItem key={i.slug} value={i.slug} className=''>
                                {i.name}
                            </SelectItem>
                          )):''
                        }

                        {
                          medicineCountryListLoaoding?
                          "":
                          medicineCountries?
                          medicineCountries.map(i => (
                            <SelectItem key={i.slug} value={i.slug} className=''>
                                {i.name}
                            </SelectItem>
                          )):''
                        }
                  </Select>
                  <Select 
                      className="w-full" 
                      // selectedKeys={pLocation}
                      // onSelectionChange={setPLocation}
                      variant=''
                      placeholder='Study Intake'
                      required
                      size='md'
                      selectedKeys={SI}
                    onSelectionChange={setSI}

                  >
                      <SelectItem key='January – March' className=''>
                          January – March
                      </SelectItem>
                      <SelectItem key='July – September' className=''>
                          July – September
                      </SelectItem>
                      <SelectItem key='October – December' className=''>
                          October – December
                      </SelectItem>
                      <SelectItem key='I’m Not Sure' className=''>
                          I’m Not Sure
                      </SelectItem>
                  </Select>
                </div>
                
                <Button onClick={sendMailBA} variant='solid' color='danger' endContent={
                    <GrFormNextLink />
                } className='w-fit font-medium bg-[#DA0C0C] text-white mt-4'>
                  Book now
                </Button>
              </div>             
            </div>
          </div>
        </div>
      </section>

      <section className='h-fit w-full'>
        <div className='h-fit w-full max-w-[1024px] mx-auto px-8 flex flex-col md:flex-row gap-6 lg:gap-8'>
          
          <div className='w-full flex flex-col gap-4'>
            <p className='w-full text-2xl font-bold text-red-500'>Our Service Categories
            </p>
            
            <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 w-full'>
                <div className='px-8 py-6 text-xs lg:text-sm border-[1px] border-black/10 border-black list-disc shadow hover:shadow-xl duration-1000 rounded-[8px]'>
                    Expert counselling tailored to meet your needs
                </div>
                <div className='px-8 py-6 text-xs lg:text-sm border-[1px] border-black/10 border-black list-disc shadow hover:shadow-xl duration-1000 rounded-[8px]'>
                    University Selection Assistance
                </div>
                <div className='px-8 py-6 text-xs lg:text-sm border-[1px] border-black/10 border-black list-disc shadow hover:shadow-xl duration-1000 rounded-[8px]'>
                    Course Selection Assistance
                </div>
                <div className='px-8 py-6 text-xs lg:text-sm border-[1px] border-black/10 border-black list-disc shadow hover:shadow-xl duration-1000 rounded-[8px]'>
                    University Application Assistance
                </div>
                <div className='px-8 py-6 text-xs lg:text-sm border-[1px] border-black/10 border-black list-disc shadow hover:shadow-xl duration-1000 rounded-[8px]'>
                    Course Application Assistance
                </div>
                <div className='px-8 py-6 text-xs lg:text-sm border-[1px] border-black/10 border-black list-disc shadow hover:shadow-xl duration-1000 rounded-[8px]'>
                    Student Visa Application Assistance
                </div>
                <div className='px-8 py-6 text-xs lg:text-sm border-[1px] border-black/10 border-black list-disc shadow hover:shadow-xl duration-1000 rounded-[8px]'>
                    Instructions on English Proficiency Exams
                </div>
                <div className='px-8 py-6 text-xs lg:text-sm border-[1px] border-black/10 border-black list-disc shadow hover:shadow-xl duration-1000 rounded-[8px]'>
                    Assistance in LOR and SOP
                </div>
                <div className='px-8 py-6 text-xs lg:text-sm border-[1px] border-black/10 border-black list-disc shadow hover:shadow-xl duration-1000 rounded-[8px]'>
                    Scholarship Assistance
                </div>
                <div className='px-8 py-6 text-xs lg:text-sm border-[1px] border-black/10 border-black list-disc shadow hover:shadow-xl duration-1000 rounded-[8px]'>
                    Expert Counsellors’ Assistance
                </div>
                <div className='px-8 py-6 text-xs lg:text-sm border-[1px] border-black/10 border-black list-disc shadow hover:shadow-xl duration-1000 rounded-[8px]'>
                    Health Cover
                </div>
                <div className='px-8 py-6 text-xs lg:text-sm border-[1px] border-black/10 border-black list-disc shadow hover:shadow-xl duration-1000 rounded-[8px]'>
                    Pre-Departure Sessions
                </div>
                <div className='px-8 py-6 text-xs lg:text-sm border-[1px] border-black/10 border-black list-disc shadow hover:shadow-xl duration-1000 rounded-[8px]'>
                    Post-Departure Sessions
                </div>
                            
            </div>
          </div>

        </div>
      </section>

      <section className='h-fit w-full'>
        <div className='h-fit w-full max-w-[1024px] mx-auto px-8 flex flex-col md:flex-row gap-6 lg:gap-8'>
          
          <div className='w-full flex flex-col gap-4'>
            <p className='w-full text-2xl font-bold text-red-500'>FAQs </p>
            
            <div className='flex flex-col gap-4 w-full'>
              
              <Accordion defaultExpandedKeys={["Approaching Comprehensive Learning"]} aria-label="Options">
                <AccordionItem key="Approaching Comprehensive Learning" title={
                  <p className='text-base font-medium'>Is Counselling free at IEBC?</p>
                }>
                  <div className='flex flex-col gap-1'>
                      <p className='text-sm text-black opacity-50 text-left'>Yes, IEBC offers free counselling services, providing personalized support tailored to 
                      each student's preferences.</p>
                  </div>  
                </AccordionItem>
                <AccordionItem key="Study Material" title={
                  <p className='text-base font-medium'>How can I get a full scholarship to study abroad?</p>
                }>
                  <div className='flex flex-col gap-1'>
                    <p className='text-sm text-black opacity-50 text-left'>To secure fully-funded scholarships, students must meet the eligibility criteria established 
by the universities. Typically, universities require students to achieve high scores to 
qualify for these scholarships.</p>
                  </div> 
                </AccordionItem>
                <AccordionItem key="Virtual Learning" title={
                  <p className='text-base font-medium'>What are the services offered by IEBC Consultancy?
</p>
                }>
                  <div className='flex flex-col gap-1'>
                    <p className='text-sm text-black text-left'>IEBC provides the following services:
                    </p>
                    <ul className='text-sm opacity-50 pl-4'>
                        <li>a) University Selection</li>
                        <li>b) Course Selection</li>
                        <li>c) University Application Assistance</li>
                        <li>d) Course Application Assistance</li>
                        <li>e) Scholarship Application Assistance</li>
                        <li>f) Student Visa Application Assistance</li>
                        <li>g) Health Insurance</li>
                        <li>h) Pre-Departure and Post-Arrival Sessions</li>
                    </ul>
                  </div>  
                </AccordionItem>
                <AccordionItem key="E-Mentoring" title={
                  <p className='text-base font-medium'>Can language be a barrier to studying abroad?</p>
                }>
                  <div className='flex flex-col gap-1'>
                    <p className='text-sm text-black opacity-50 text-left'>Yes, language can indeed be a barrier to studying abroad. However, with sufficient 
                    preparation and language training, this obstacle can be overcome.</p>
                  </div>
                </AccordionItem>
                <AccordionItem key="Web-Based Support Services" title={
                  <p className='text-base font-medium'>Which country is easiest to get a study visa?</p>
                }>
                  <div className='flex flex-col gap-1'>
                    <p className='text-sm text-black opacity-50 text-left'>Countries like the US, the UK, Canada, Australia, Ireland, New Zealand, Germany, and 
Dubai are known for their quicker study visa processing times, usually taking only 3-4 
weeks to issue a visa to international students.</p>
                  </div>
                </AccordionItem>
                <AccordionItem key="Web-Based Support Services" title={
                  <p className='text-base font-medium'>What documentation should I submit with my application for approval?</p>
                }>
                  <div className='flex flex-col gap-1'>
                  <ul className='text-sm opacity-50'>
                        <li>a) Academic transcripts</li>
                        <li>b) English Language proficiency exam score certificate</li>
                        <li>c) Statement of Purpose (SOP)</li>
                        <li>d) Letters of Recommendation (LORs)</li>
                        <li>e) Proof of Funds</li>
                    </ul>
                  </div>
                </AccordionItem>
              </Accordion> 
            </div>
          </div>

        </div>
      </section>
    </div>
  )
}

export default AbroadServiceScreen